import React, {useEffect, useState} from "react";
import {Row} from "react-bootstrap";
import queryString from "query-string";
import {useHistory, useLocation} from "react-router-dom";
import SalesOverTime from "./SalesOverTime";
import SalesByManufacturer from "./SalesByManufacturer";
import SalesByCategory from "./SalesByCategory";
import translate from "../../i18n/translate";
import SalesByEndUser from "./SalesByEndUser";

enum Tabs {
    MONTH = "month",
    MANUFACTURER = "manufacturer",
    CATEGORY = "category",
    END_USER = "end-user"
}

export const COLOURS = [
    "#009CDE",
    "#005F94",
    "#A3D4F7",
    "#FF7300",
    "#b3b3b3"
];

const TechSales: React.FC = () => {

    let location = useLocation();
    const history = useHistory();
    const parsed = queryString.parse(location.search);

    const [view, setView] = useState(parsed.view);
    useEffect(() => {
        const parsed = queryString.parse(location.search);
        setView(parsed.view);
    }, [location]);

    const updateView = (view: string) => {
        var link: string = "/tech-sales";
        if (view !== "") {
            link += "?view=" + view;
        }
        history.push(link);
    };

    return (
        <div style={{ marginTop: 15, marginRight: "auto", paddingLeft: 15 }}>
            <div className="iq-headline">
                <span className="text">
                    Our TD SYNNEX Sales
                </span>
            </div>

            <Row className="mb-4" style={{ marginLeft: 0, marginRight: 0 }}>
                <div style={{ marginLeft: "auto", marginRight: "auto" }} className="iq-button-group">
                    <button className={(view === Tabs.MONTH || view === undefined) ? "selected-inverted" : ""}
                            onClick={(e) => { updateView(Tabs.MONTH) }}>
                        {translate("techsales.month")}
                    </button>
                    <button className={view === Tabs.MANUFACTURER ? "selected-inverted" : ""}
                            onClick={(e) => { updateView(Tabs.MANUFACTURER) }}>
                        {translate("techsales.manufacturer")}
                    </button>
                    <button className={view === Tabs.CATEGORY ? "selected-inverted" : ""}
                            onClick={(e) => { updateView(Tabs.CATEGORY) }}>
                        {translate("techsales.category")}
                    </button>
                    <button className={view === Tabs.END_USER ? "selected-inverted" : ""}
                            onClick={(e) => { updateView(Tabs.END_USER) }}>
                        {translate("techsales.enduser")}
                    </button>
                </div>
            </Row>

            <div>
                {(view === undefined || view === Tabs.MONTH) && (
                    <SalesOverTime />
                )}
            </div>
            <div>
                {view === Tabs.MANUFACTURER && (
                    <SalesByManufacturer />
                )}
            </div>
            <div>
                {view === Tabs.CATEGORY && (
                    <SalesByCategory />
                )}
            </div>
            <div>
                {view === Tabs.END_USER && (
                    <SalesByEndUser />
                )}
            </div>
        </div>
    );
};

export default TechSales;